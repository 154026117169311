.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.Shadow {
    box-shadow: 0 0 10px 0 hsl(0, 0%, 0%);
}

.App-header {
    background-color: hsl(0, 0%, 80%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Rounded {
    border-radius: 10px;
}

.App-background-main {
    background-color: hsl(193, 44%, 36%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.App-background-secondary {
    background-color: hsl(193, 44%, 36%);
}

.Fill-width-95 {
    min-width: 95%;
}

.Fill-width-80 {
    min-width: 80%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
